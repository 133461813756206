import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import camera_place_holder from "../../Global/images/camera_place_holder.jpg";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { useParams } from "react-router-dom";
import {
  useEditJobCardMutation,
  useEditIMSSmartnodeMutation, useDownloadDigitalCOCMutation, useAddChangeLogMutation
} from "../api/apiSlice";
import CircularProgress from "@mui/material/CircularProgress";

import theme from "../../Global/theme";
import { useSelector } from "react-redux";

import { getUserName } from "../Authentication/authSlice";
import {
  showError,
  showSuccess,
} from "../../Global/notifications/notificationsSlice";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { useDispatch } from "react-redux";
import {

  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
} from "@mui/material";

export function DigitalCOCCapture() {
  let { id, job_card_reference_id, serial_number, imsi_number, install_state } = useParams();
  const dispatch = useDispatch();
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const user_name = useSelector(getUserName);

  const [editIMSSmartNode, { isSuccess: isEditIMSSmartNodeSuccess }] =
    useEditIMSSmartnodeMutation();

  let navigate = useNavigate();
  const [
    editJobCard,
    { isSuccess: isEditJobCardSuccess, isLoading: isEditJobCardLoading },
  ] = useEditJobCardMutation();


   const [
    addChangeLog,
    {
      isSuccess: addChangeLogSuccess,
      isError: isaddChangeLogError,
      error: addChangeLogError,
    },
  ] = useAddChangeLogMutation();
  
  const [first_photo, setFirstPhoto] = useState(null);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleSubmitPhotos = () => {
    const body = new FormData();
    body.append("digital_coc_image", first_photo);
    body.append("reference_id", job_card_reference_id);

    body.append("qe_job_state", "Approved");
    body.append("qe_name", user_name);
    body.append("state", "COM");

    editJobCard({ body: body });
  };

  React.useEffect(() => {
    if (isEditJobCardSuccess && install_state!="UN") {
      const body = {
        serial_number: serial_number,
        imsi_number: imsi_number,
        status: "In Field",
        change: "QE approved install and device moved to in field"
      };
      editIMSSmartNode({ body: body });
    }
    else if(isEditJobCardSuccess && install_state=="UN"){
        const body = {
            serial_number:serial_number,
            change:"QE Approved uninstall",
          };
          addChangeLog({ body: body });
      
    }
  }, [isEditJobCardSuccess]);

  React.useEffect(() => {
    if (isEditIMSSmartNodeSuccess || addChangeLogSuccess) {
      setOpenApproveDialog(true);
     
    }
  }, [isEditIMSSmartNodeSuccess, addChangeLogSuccess]);


  if (isMobile) {
    return (
      <>
        <Grid container alignItems="center" justifyContent="center" sx={{mt:10}}>
          <TakePhoto
            photo={first_photo}
            setPhoto={setFirstPhoto}
            handleSubmitPhotos={handleSubmitPhotos}
          />
          <ApproveDialogs openApproveDialog={openApproveDialog} setOpenApproveDialog={setOpenApproveDialog} id={id} job_card_reference_id={job_card_reference_id}/>
        </Grid>
      </>
    );
  } else {
    return <p>Sorry, this feature is only available on mobile devices.</p>;
  }
}

function TakePhoto({
  photo,
  setPhoto,
  handleSubmitPhotos,
  submit_photos_loading,
}) {
  const [no_photo_error, setNoPhotoError] = useState(false);
  const [are_you_sure_flag, setAreYouSureFlag] = useState(false);
  const [frontend_photo_placeholder, setFrontendPhotoPlaceholder] =
    useState(camera_place_holder);

  const handleCapture = (event) => {
    const file = event.target.files[0];
    const photoURL = URL.createObjectURL(file);
    setPhoto(file);
    setFrontendPhotoPlaceholder(photoURL);
    setNoPhotoError(false);
  };

  const HandleNextButton = () => {
    if (photo === null) {
      setNoPhotoError(true);
    } else {
      handleSubmitPhotos();
      setAreYouSureFlag(false);
    }
  };

  return (
    <>
      <input
        accept="image/*"
        id="icon-button-file"
        type="file"
        capture="environment"
        onChange={handleCapture}
        style={{ display: "none" }}
      />

      <Grid item xs={1} />
      <Grid item xs={10} align="center">
        {frontend_photo_placeholder && (
          <img
            src={frontend_photo_placeholder}
            alt="Captured"
            style={{ objectFit: "cover", width: "200px", height: "200px" }}
          />
        )}
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={1} />
      <Grid item xs={10} align="center">
        <label htmlFor="icon-button-file">
          <Button
            variant="outlined"
            style={theme.blackButtonStyle.root}
            component="span"
            sx={{ mt: 2 }}
          >
            {photo === null ? "Capture Photo" : "Re-take Picture"}
          </Button>
        </label>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={1} />
      <Grid item xs={10} align="center">
        <Collapse in={no_photo_error}>
          <Alert severity="error" sx={{ mt: 2 }}>
            You have not taken any photos yet
          </Alert>
        </Collapse>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={1} />
      <Grid item xs={10} align="center">
        <Collapse in={photo !== null}>
          <Alert severity="warning" sx={{ mt: 1 }}>
            Once you've clicked confirm, you cannot retake this photo!
          </Alert>
        </Collapse>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={1} />
      <Grid item xs={10} align="center">
        {submit_photos_loading ? (
          <CircularProgress sx={{ mt: 2 }} />
        ) : (
          <Button
            variant="contained"
            // style={theme.yellowButtonStyle.root}
            sx={{ ...theme.yellowButtonStyle.root,
              mt: 4 }}
            onClick={HandleNextButton}
             disabled= {photo === null}
          >
            CONFIRM
          </Button>
        )}
      </Grid>
      <Grid item xs={1} />
    </>
  );
}



function ApproveDialogs({openApproveDialog, setOpenApproveDialog, id, job_card_reference_id}){
 
    let navigate = useNavigate();


    const handleApprove = () => {
      navigate(`/QE/`)
    };

    return(
      <Dialog open={openApproveDialog} onClose={() => setOpenApproveDialog(false)}>
          <DialogTitle>
         Install Approved
          </DialogTitle>
          <DialogContent>
            <p> {job_card_reference_id} has been approved and digital COC has been attached.</p>
           
          </DialogContent>
          <DialogActions>
           <DownloadCOC id={id}/>
            <Button onClick={handleApprove} variant="contained" sx={{ backgroundColor: "#FDB940", color: "#000" }}>
             Home
            </Button>
          </DialogActions>
        </Dialog>
    )
  }
  function DownloadCOC({ id }) {
    const [
      downloadImages,
      {
        isSuccess: isDownloadSuccess,
        isError: isDownloadError,
        error: downloadError,
        data: downloadData,
      },
    ] = useDownloadDigitalCOCMutation();
  

    const downloadInstallationImages = () => {
      const request = { job_card_id: id };
  
      downloadImages(request)
        .unwrap()
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
  
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `job_card_${id}_digital_coc.zip`);
  
          document.body.appendChild(link);
          link.click();
  
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Download failed:", error);
          alert("Failed to download file. Please try again.");
        });
    };
  
    return (
      <>
        <Grid item xs={0.25} />
        <Grid item xs={11.5}>
          <Button
            startIcon={<ArrowDownwardIcon />}
            onClick={downloadInstallationImages}
          >
            DOWNLOAD COC
          </Button>
        </Grid>
      </>
    );
  }
  