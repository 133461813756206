import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useGetAddressQuery, useUpdateCoordsMutation } from "../api/apiSlice";
import { LoadScript } from "@react-google-maps/api";

const styles = {
    container: {
        margin: "0 auto",
        padding: "0 16px",
        maxWidth: "1350px",
        marginTop: 70,
    },
    formField: {
        marginBottom: "1rem",
    },
    label: {
        display: "block",
        marginBottom: "0.5rem",
        fontWeight: "bold",
    },
    input: {
        width: "100%",
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "4px",
    },
    button: {
        padding: "10px 20px",
        backgroundColor: "blue",
        color: "white",
        border: "none",
        cursor: "pointer",
        borderRadius: "4px",
    },
    errorMessage: {
        color: "red",
        fontSize: "0.9rem",
    },
};

const libraries = ["places"];

export default function CoordUpdatePage() {
    const [address, setAddress] = useState("");
    const [unitNumber, setUnitNumber] = useState("");
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    const { data: address_data, isSuccess: isGetAddressSuccess, isError: isGetAddressError } =
        useGetAddressQuery();

    const [
        updateCoords,
        { isSuccess: isUpdateCoordsSuccess, isError: isUpdateCoordsError },
    ] = useUpdateCoordsMutation();

    const setCoordinates = ({ latitude, longitude }) => {
        setLatitude(latitude);
        setLongitude(longitude);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!address || !latitude || !longitude) {
            alert("Please complete the address field using the autocomplete suggestion.");
            return;
        }
        const id = address_data.id;
        const body = {
            id: id,
            address: address,
            unit_number: unitNumber,
            latitude: latitude,
            longitude: longitude
        };

        try {
            await updateCoords(body).unwrap();
            alert("Coordinates updated successfully!");
            window.location.reload();
        } catch (error) {
            console.error("Failed to update coordinates:", error);
            alert("Error updating coordinates. Please try again.");
        }
    };

    if (!address_data && !isGetAddressError) {
        return <p>Loading...</p>;
    }

    if (isGetAddressError) {
        return <p style={styles.errorMessage}>Error fetching address. Please try again later.</p>;
    }

    return (
        <form onSubmit={handleSubmit} style={{ maxWidth: "400px", margin: "auto" }}>
            <h3>Update Address</h3>

            <div style={styles.formField}>
                <label style={styles.label}>Serial Number:</label>
                <p>{address_data?.serial_number || "Not available"}</p>
            </div>

            <div style={styles.formField}>
                <label style={styles.label}>Original Address:</label>
                <p>{address_data?.address || "Not available"}</p>
            </div>

            <div style={styles.formField}>
                <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    libraries={libraries}
                >
                    <AddressTextField
                        setterVariable={address}
                        setterFunction={setAddress}
                        setCoordinates={setCoordinates}
                        label="Address"
                    />
                </LoadScript>
            </div>

            <div style={styles.formField}>
                <label style={styles.label}>Unit Number:</label>
                <input
                    type="text"
                    value={unitNumber}
                    onChange={(e) => setUnitNumber(e.target.value)}
                    placeholder="Enter unit number"
                    style={styles.input}
                />
            </div>

            {isUpdateCoordsError && (
                <p style={styles.errorMessage}>
                    Failed to update coordinates. Please try again.
                </p>
            )}

            <button type="submit" style={styles.button}>
                Submit
            </button>
        </form>
    );
}

function AddressTextField({ setterVariable, setterFunction, setCoordinates, label }) {
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (window.google) {
            const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
                types: ["geocode"],
                componentRestrictions: { country: "ZA" },
            });

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                const formattedAddress = place.formatted_address || inputValue;

                const coordinates = place.geometry?.location
                    ? {
                          latitude: place.geometry.location.lat(),
                          longitude: place.geometry.location.lng(),
                      }
                    : null;

                setInputValue(formattedAddress);
                setterFunction(formattedAddress);

                if (coordinates) {
                    setCoordinates(coordinates);
                }
            });
        }
    }, []);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setterFunction(e.target.value);
    };

    return (
        <Grid item xs={12} style={styles.formField}>
            <label style={styles.label}>{label}</label>
            <TextField
                inputRef={inputRef}
                margin="normal"
                value={inputValue}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
            />
        </Grid>
    );
}
