import { useState } from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import {
  useGetMVGraphDataQuery,
  useGetMVTableDataQuery,
} from "./municipalityDataAPISlice";
import dayjs from "dayjs";
import DateRangePickerMunicipality from "./DateRangePickerMunicipality";
import MunicipalityGraph from "./MunicipalityGraph";
import MunicipalityTable from "./MunicipalityTable";
import GraphLabels from "./GraphLabels";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

export default function LandingPageMunicipality({}) {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [startDate, setStartDate] = useState(dayjs().subtract(1, "day"));
  const [endDate, setEndDate] = useState(dayjs());

  const request = {
    queryParams: {
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      userTimezone: userTimezone,
    },
  };

  const {
    data: municipalityGraphData,
    isLoading: isLoadingMunicipalityGraphData,
    isSuccess: isSuccessMunicipalityGraphData,
    isError: isErrorMunicipalityGraphData,
    error: errorMessageMunicipalityGraphData,
  } = useGetMVGraphDataQuery(request);

  const {
    data: municipalityTableData,
    isLoading: isLoadingMunicipalityTableData,
    isSuccess: isSuccessMunicipalityTableData,
    isError: isErrorMunicipalityTableData,
    error: errorMessageMunicipalityTableData,
  } = useGetMVTableDataQuery(request);

  return (
      <>
    <DateRangePickerMunicipality
    setStartDate={setStartDate}
    setEndDate={setEndDate}
    startDate={startDate}
    endDate={endDate}
  />
    <Grid
      container
      style={{
        marginTop: "4.5em",
        // padding: "2em",
        // maxWidth: "1700px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      
     
<Grid item xs={0.5}/>
      <Grid item xs={11} sx={{mt:15}}>
        <Card sx={{ borderRadius: "12px", backgroundColor: "#F6F6F6" }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{ fontSize: "25px", color: "black" }}
              sx={{ mt: 5, mb: 3, ml: 3 }}
            >
              Demand M&V
            </Typography>
            <Typography
              style={{ fontSize: "25px", color: "black" }}
              sx={{ mt: 5, mb: 3, mr: 3 }}
            >
              Current Target Maximum Demand: {municipalityGraphData?.target_demand / 1000000} MVA
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2, mt: 2, ml: 2, mr: 2 }}>
            <MunicipalityGraph
              municipalityGraphData={municipalityGraphData}
              isSuccessMunicipalityGraphData={isSuccessMunicipalityGraphData}
              isErrorMunicipalityGraphData={isErrorMunicipalityGraphData}
              errorMessageMunicipalityGraphData={
                errorMessageMunicipalityGraphData
              }
              isLoadingMunicipalityGraphData={isLoadingMunicipalityGraphData}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2, mt: 2, ml: 2, mr: 2 }}>
            <GraphLabels />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2, mt: 2, ml: 2, mr: 2 }}>
            <MunicipalityTable
              municipalityTableData={municipalityTableData}
              isLoadingMunicipalityTableData={isLoadingMunicipalityTableData}
              isSuccessMunicipalityTableData={isSuccessMunicipalityTableData}
              isErrorMunicipalityTableData={isErrorMunicipalityTableData}
              errorMessageMunicipalityTableData={
                errorMessageMunicipalityTableData
              }
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={0.5}/>
    </Grid>
    </>
  );
}
