import React, { useState } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";
import {
  useGetLSPSStateQuery,
  useUpdateLSPSStateMutation,
  useGetMicroServiceMonitoringQuery,
  useGetTargetDataNowQuery,
  useGetMicroServiceMonitoringForcastQuery,
} from "./operatorDashboardAPISlice";
import { useGetTotalInstalledCapacityQuery } from "../api/apiSlice";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ResetGeysers from "../../Features/OperatorDashboard/ResetGeysers";
import IconButton from "@mui/material/IconButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useNavigate } from "react-router-dom";

const shapeCircleStyles = { borderRadius: "50%" };
const shapeDashedTargetDemandLineStyles = {
  width: "100%",
  height: "100%",
  border: "none",
  borderTop: "3px dashed #26BF00",
  borderBottom: "3px dashed #26BF00",
};
const shapeDashedMaxReturnLineStyles = {
  width: "100%",
  height: "100%",
  border: "none",
  borderTop: "3px dashed #FF5C00",
  borderBottom: "3px dashed #FF5C00",
};
const shapeIntegratedDemandLineStyles = {
  width: "100%",
  height: "100%",
};

const currentDemandStyles = { bgcolor: "#2E93FA", width: 20, height: 20 };
const targetDemandStyles = { bgcolor: "#26BF00", width: 30, height: 1.5 };
const maxReturnStyles = { bgcolor: "#FF5C00", width: 30, height: 1.5 };
const integratedDemandStyles = { bgcolor: "#A53838", width: 30, height: 6 };
const forecastStyles = { bgcolor: "#DC6CFE", width: 20, height: 20 };
const forecastPrevStyles = {
  bgcolor: "#DC6CFE",
  opacity: 0.5,
  width: 20,
  height: 20,
};

const currentDemandCircle = (
  <Box component="span" sx={{ ...currentDemandStyles, ...shapeCircleStyles }} />
);

const forecastCircle = (
  <Box component="span" sx={{ ...forecastStyles, ...shapeCircleStyles }} />
);

const forecastPrevCircle = (
  <Box component="span" sx={{ ...forecastPrevStyles, ...shapeCircleStyles }} />
);

const targetDemandLine = (
  <Box
    component="span"
    sx={targetDemandStyles}
    display="flex"
    alignItems="left"
    justifyContent="left"
    position="relative"
  >
    <span style={shapeDashedTargetDemandLineStyles}></span>
  </Box>
);

const maxReturnLine = (
  <Box
    component="span"
    sx={maxReturnStyles}
    display="flex"
    alignItems="center"
    justifyContent="center"
    position="relative"
  >
    <span style={shapeDashedMaxReturnLineStyles}></span>
  </Box>
);

const tjommieDemand = (
  <Box component="span" sx={{ width: 20, height: 20, bgcolor: "#CACACA" }} />
);

const integratedDemandLine = (
  <Box
    component="span"
    sx={integratedDemandStyles}
    display="flex"
    alignItems="center"
    justifyContent="center"
    position="relative"
  >
    <span style={shapeIntegratedDemandLineStyles}></span>
  </Box>
);
export default function OperatorDashboardDemand() {
  const navigate = useNavigate();
  const [maxReturn, setMaxReturn] = useState(0);
  const [currentDemandTooltip, setcurrentDemandTooltip] = useState(false);
  const [targetDemandTooltip, settargetDemandTooltip] = useState(false);
  const [integratedDemandTooltip, setintegratedDemandTooltip] = useState(false);
  const [tjommieDemandTooltip, settjommieDemandTooltip] = useState(false);
  const [forcastTooltip, setforcastTooltip] = useState(false);
  const [maxReturnTooltip, setmaxReturnTooltip] = useState(false);

  const handleTooltipClose = (tooltipType) => {
    if (tooltipType === "currentDemandTooltip") {
      setcurrentDemandTooltip(false);
    } else if (tooltipType === "targetDemandTooltip") {
      settargetDemandTooltip(false);
    } else if (tooltipType === "integratedDemandTooltip") {
      setintegratedDemandTooltip(false);
    } else if (tooltipType === "tjommieDemandTooltip") {
      settjommieDemandTooltip(false);
    } else if (tooltipType === "forcastTooltip") {
      setforcastTooltip(false);
    } else if (tooltipType === "maxReturnTooltip") {
      setmaxReturnTooltip(false);
    }
  };

  const handleTooltipOpen = (tooltipType) => {
    if (tooltipType === "currentDemandTooltip") {
      setcurrentDemandTooltip(true);
    } else if (tooltipType === "targetDemandTooltip") {
      settargetDemandTooltip(true);
    } else if (tooltipType === "integratedDemandTooltip") {
      setintegratedDemandTooltip(true);
    } else if (tooltipType === "tjommieDemandTooltip") {
      settjommieDemandTooltip(true);
    } else if (tooltipType === "forcastTooltip") {
      setforcastTooltip(true);
    } else if (tooltipType === "maxReturnTooltip") {
      setmaxReturnTooltip(true);
    }
  };

  const {
    data: totalInstalledCapacity,
    isLoading: totalInstalledCapacityLoading,
    isError: totalInstalledCapacityError,
    isSuccess: installedCapacitySuccess,
  } = useGetTotalInstalledCapacityQuery();
  const {
    data: lspsState,
    isLoading: isLSPSStateLoading,
    isError: isLSPSStateError,
  } = useGetLSPSStateQuery(null, {
    pollingInterval: 60000,
  });
  const {
    data: microserviceState,
    isLoading: isMicroServiceStateLoading,
    isError: isMicroServiceStateError,
    refetch: refetchMicroService,
  } = useGetMicroServiceMonitoringQuery(null, {
    pollingInterval: 60000,
  });
  const {
    data: microserviceForcastState,
    isLoading: isMicroServiceForcastStateLoading,
    isError: isMicroServiceStateForcastError,
    refetch: refetchMicroServiceForcast,
  } = useGetMicroServiceMonitoringForcastQuery(null, {
    pollingInterval: 60000,
  });
  const {
    data: targetDataNow,
    isLoading: isTargetDataNowLoading,
    isError: isTargetDataNowError,
    refetch: refetchTargetDataNow,
  } = useGetTargetDataNowQuery(null, {
    pollingInterval: 60000,
  });

  const [
    updateLSPSState,
    {
      data: updatedLSPSState,
      isLoading: updateLSPSStateLoading,
      isError: updateLSPSStateError,
      error: LSPSStateUpdateError,
    },
  ] = useUpdateLSPSStateMutation();

  const handleBlur = () => {
    const request = {
      body: {
        max_return: maxReturn,
      },
    };
    updateLSPSState(request);
  };
  const handleChange = (event) => {
    const newMaxReturn = event.target.value.replace(/,/g, ".");

    if (/^\d*\.?\d*$/.test(newMaxReturn) || newMaxReturn === "") {
      setMaxReturn(newMaxReturn);
    }
  };
  React.useEffect(() => {
    if (lspsState) {
      setMaxReturn(lspsState.max_return);
    }
  }, [lspsState]);

  const handleHistoricalGraph = () => {
    navigate("/operator/historical-graph/");
  };

  return (
    <>
      <Grid container>
        <Grid item md={3.9} style={{ margin: "4px" }}>
          <ClickAwayListener
            onClickAway={() => handleTooltipClose("currentDemandTooltip")}
          >
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => handleTooltipClose("currentDemandTooltip")}
              open={currentDemandTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="The current total demand as 
            metered at the bulk meter"
              placement="bottom-start"
              arrow={true}
            >
              <Paper
                elevation={0}
                sx={{
                  padding: "30px",
                  backgroundColor: "#F8F8F8",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => handleTooltipOpen("currentDemandTooltip")}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                >
                  <Grid item>
                    <Badge overlap="circular">{currentDemandCircle}</Badge>
                  </Grid>
                  <Grid item>
                    <Typography>Current Demand</Typography>
                  </Grid>
                  {isMicroServiceStateLoading ? (
                    <CircularProgress />
                  ) : (
                    <Grid item>
                      <Typography fontWeight="bold">
                        {microserviceState?.current_demand !== undefined
                          ? Math.round(microserviceState?.current_demand) /
                            1000000
                          : "No Demand"}{" "}
                        MVA
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Tooltip>
          </ClickAwayListener>
        </Grid>

        <Grid item md={3.9} style={{ margin: "4px" }}>
          <ClickAwayListener
            onClickAway={() => handleTooltipClose("targetDemandTooltip")}
          >
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => handleTooltipClose("targetDemandTooltip")}
              open={targetDemandTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="The current target demand as initially set 
            by the Operator for the month and 
            updated by the system if exceeded"
              placement="bottom-start"
              arrow={true}
            >
              <Paper
                elevation={0}
                sx={{
                  padding: "30px",
                  backgroundColor: "#F8F8F8",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => handleTooltipOpen("targetDemandTooltip")}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                >
                  <Grid item>
                    <Badge>{targetDemandLine}</Badge>
                  </Grid>
                  <Grid item>
                    <Typography>Target Demand</Typography>
                  </Grid>
                  {isTargetDataNowLoading ? (
                    <CircularProgress />
                  ) : (
                    <Grid item>
                      <Typography fontWeight="bold">
                        {targetDataNow?.target !== undefined
                          ? Math.round(targetDataNow?.target) / 1000000
                          : "No Target"}{" "}
                        MVA
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Tooltip>
          </ClickAwayListener>
        </Grid>
        <Grid item md={3.9} style={{ margin: "4px" }}>
          <ClickAwayListener
            onClickAway={() => handleTooltipClose("integratedDemandTooltip")}
          >
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => handleTooltipClose("integratedDemandTooltip")}
              open={integratedDemandTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="The current integrated demand. 
            (Resets to zero at the start of the metering period)"
              arrow={true}
            >
              <Paper
                elevation={0}
                sx={{
                  padding: "30px",
                  backgroundColor: "#F8F8F8",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => handleTooltipOpen("integratedDemandTooltip")}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  gap={1}
                >
                  <Grid item>
                    <Badge>{integratedDemandLine}</Badge>
                  </Grid>
                  <Grid item>
                    <Typography>Integrated Demand</Typography>
                  </Grid>
                  {isMicroServiceStateLoading ? (
                    <CircularProgress />
                  ) : (
                    <Grid item>
                      <Typography fontWeight="bold">
                        {microserviceState?.latest_integrated_demand !==
                        undefined
                          ? Math.round(
                              microserviceState?.latest_integrated_demand
                            ) / 1000000
                          : "No Demand"}{" "}
                        MVA
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Tooltip>
          </ClickAwayListener>
        </Grid>
        <Grid item md={3.9} style={{ margin: "4px" }}>
          <ClickAwayListener
            onClickAway={() => handleTooltipClose("tjommieDemandTooltip")}
          >
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => handleTooltipClose("tjommieDemandTooltip")}
              open={tjommieDemandTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="The current geyser demand/ load"
              arrow={true}
            >
              <Paper
                elevation={0}
                sx={{
                  padding: "30px",
                  backgroundColor: "#F8F8F8",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => handleTooltipOpen("tjommieDemandTooltip")}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  gap={1}
                >
                  <Grid item>
                    <Badge>{tjommieDemand}</Badge>
                  </Grid>
                  <Grid item>
                    <Typography>Tjommie Demand</Typography>
                  </Grid>
                  {isMicroServiceStateLoading ? (
                    <CircularProgress />
                  ) : (
                    <Grid item>
                      <Typography fontWeight="bold">
                        {microserviceState?.geyser_demand !== undefined
                          ? Math.round(microserviceState?.geyser_demand) /
                            1000000
                          : "No Demand"}{" "}
                        MVA
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Tooltip>
          </ClickAwayListener>
        </Grid>

        <Grid item md={3.9} style={{ margin: "4px" }}>
          <ClickAwayListener
            onClickAway={() => handleTooltipClose("tjommieDemandTooltip")}
          >
            <Paper
              elevation={0}
              sx={{
                padding: "10px",
                paddingY: "30px",
                backgroundColor: "#F8F8F8",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={() => handleTooltipOpen("tjommieDemandTooltip")}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                
              >
                <Grid item>
                  <Typography noWrap>
                    Total Geyser Installed Capacity
                  </Typography>
                </Grid>
                
                {totalInstalledCapacityLoading ? (
                  <CircularProgress />
                ) : (
                  <Grid item>
                    <Typography noWrap fontWeight="bold">
                      {totalInstalledCapacity !== undefined
                        ? totalInstalledCapacity
                        : "No value"}{" "}
                      MVA
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Paper>

            {/* </Tooltip> */}
          </ClickAwayListener>
        </Grid>
        <Grid item md={3.9} style={{ margin: "4px" }}>
          <ClickAwayListener
            onClickAway={() => handleTooltipClose("forcastTooltip")}
          >
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => handleTooltipClose("forcastTooltip")}
              open={forcastTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="The forecast for the total demand at 
            the end of the current metering period"
              arrow={true}
            >
              <Paper
                elevation={0}
                sx={{
                  padding: "12px",
                  backgroundColor: "#F8F8F8",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => handleTooltipOpen("forcastTooltip")}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                >
                  <Grid item>
                    <Badge>{forecastCircle}</Badge>
                  </Grid>
                  <Grid item>
                    <Typography>Forecast</Typography>
                  </Grid>
                  {isMicroServiceForcastStateLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Grid item>
                        {microserviceForcastState[0]?.forecast >
                        microserviceForcastState[1].forecast ? (
                          <ArrowUpwardIcon sx={{ color: "#ff0000" }} />
                        ) : (
                          <ArrowDownwardIcon sx={{ color: "#358B00" }} />
                        )}
                      </Grid>
                      <Grid item>
                        <Typography fontWeight="bold">
                          {microserviceForcastState[0]?.forecast !== undefined
                            ? Math.round(
                                microserviceForcastState[0]?.forecast
                              ) / 1000000
                            : "No Forcast"}{" "}
                          MVA
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        alignItems="right"
                        justifyContent="space-between"
                        gap={1}
                      >
                        <Grid item>
                          <Badge>{forecastPrevCircle}</Badge>
                        </Grid>
                        <Grid item>
                          <Typography fontWeight="bold">
                            {microserviceForcastState[1]?.forecast !== undefined
                              ? Math.round(
                                  microserviceForcastState[1]?.forecast
                                ) / 1000000
                              : "No Forcast"}{" "}
                            MVA
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Paper>
            </Tooltip>
          </ClickAwayListener>
        </Grid>
        <Grid item md={3.9} style={{ margin: "4px" }}>
          <ClickAwayListener
            onClickAway={() => handleTooltipClose("maxReturnTooltip")}
          >
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => handleTooltipClose("maxReturnTooltip")}
              open={maxReturnTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="The maximum total demand allowed on the system"
              arrow={true}
            >
              <Paper
                variant="outlined"
                sx={{
                  padding: "15px",
                  backgroundColor: "#FFF9E3",
                  borderColor: "#FDB940",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => handleTooltipOpen("maxReturnTooltip")}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="nowrap"
                  gap={1}
                >
                  <Grid item>
                    <Badge>{maxReturnLine}</Badge>
                  </Grid>
                  <Grid item sx={{ flexShrink: 0 }}>
                    {" "}
                    <Typography noWrap>MAX RETURN LOAD (MW):</Typography>
                  </Grid>
                  {isLSPSStateLoading || updateLSPSStateLoading ? (
                    <CircularProgress />
                  ) : (
                    <Grid item>
                      <TextField
                        sx={{
                          backgroundColor: "#FFFFFF",
                          maxWidth: "90px",
                        }}
                        name="max_return"
                        type="text"
                        onBlur={handleBlur}
                        value={maxReturn || 0}
                        onChange={handleChange}
                      />
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Tooltip>
          </ClickAwayListener>
        </Grid>
        <Grid item md={3} style={{ display: "flex", margin: "4px" }}>
          <ResetGeysers />
        </Grid>
        <Grid
          item
          md={4.8}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "-50px",
          }}
        >
          <IconButton
            onClick={handleHistoricalGraph}
            aria-label="calendar-button"
            size="small"
            sx={{
              color: "#2E93FA",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <CalendarMonthIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}
