import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import { useGetDSMMetersQuery } from "./operatorDashboardAPISlice";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate } from "react-router-dom";
import FlagIcon from "@mui/icons-material/Flag";

function GoTo({ obj }) {
  const navigate = useNavigate();

  const handleClick = (serial_number, id) => {
    navigate(`/operator/historical-data/${serial_number}/${id}/`);
  };
  if (obj.hwi === "No geyser connected" || obj.hwi === "No device connected") {
    return <Chip label={obj.serial_number}></Chip>;
  } else {
    return (
      <Chip
        label={obj.serial_number}
        onClick={() => handleClick(obj.serial_number, obj.id)}
      ></Chip>
    );
  }
}
const columns = [
  {
    field: "serial_number",
    headerName: "ID",
    width: "200",
    renderCell: (params) => <GoTo obj={params.row} />,
  },
  { field: "hwi", headerName: "HWI", width: "200" },
  { field: "switch_status", headerName: "Switch", width: "100" },
  { field: "control_status", headerName: "Control", width: "150" },
  { field: "power", headerName: "Power", width: "200" },
  {
    field: "last_switch_controlled",
    headerName: "Last Used switch",
    width: "200",
  },
  {
    field: "lower_limit_flagged",
    headerName: "Lower Limit",
    width: "150",
    renderCell: (params) => {
      const flagged = params.row.lower_limit_flagged;
      return flagged ? <FlagIcon sx={{ color: "red"}} /> : null;
    },
  },
  {
    field: "is_baseline",
    headerName: "Baseline",
    width: "150",
    renderCell: (params) => {
      const baseline = params.row.is_baseline;
      return baseline ? <FlagIcon sx={{ color: "#03A60A"}} /> : null;
    },
  },
];

export default function OperatorDataGrid() {
  const [serialNumber, setSerialNumber] = React.useState("");
  const [controlStatus, setControlStatus] = React.useState("");
  const [connectionStatus, setConnectionStatus] = React.useState("Connected");

  const [switchStatus, setSwitchStatus] = React.useState("");
  const [isBaseline, setIsBaseline] = React.useState("");
  const [filteredRows, setFilteredRows] = React.useState([]);

  const [tempQuery, setTempQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [queryParams, setParamsQuery] = useState("");

  const handleConnectionFilterChange = (event) => {
    const newConnectionStatus =
      connectionStatus === event.target.value ? "" : event.target.value;

    setConnectionStatus(newConnectionStatus);

    setParamsQuery((prevParams) => ({
      ...prevParams,
      connectionStatus: newConnectionStatus,
    }));
  };

  const handleControlFilterChange = (event) => {
    const newControlStatus =
      controlStatus === event.target.value ? "" : event.target.value;

    setControlStatus(newControlStatus);

    setParamsQuery((prevParams) => ({
      ...prevParams,
      controlStatus: newControlStatus,
    }));
  };
  const handleSwitchStatusFilterChange = (event) => {
    const newSwitchStatus =
      switchStatus === event.target.value ? "" : event.target.value;

    setSwitchStatus(newSwitchStatus);

    setParamsQuery((prevParams) => ({
      ...prevParams,
      switchStatus: newSwitchStatus,
    }));
  };

  const handleisBaselineFilterChange = (event) => {
    const newIsBaseline =
      isBaseline === event.target.value ? "" : event.target.value;

    setIsBaseline(newIsBaseline);

    setParamsQuery((prevParams) => ({
      ...prevParams,
      isBaseline: newIsBaseline,
    }));
  };

  const handleSearchQuery = (tempQuery) => {
    setParamsQuery((prevParams) => ({
      ...prevParams,
      searchQuery: tempQuery,
      connectionStatus: tempQuery ? "" : prevParams.connectionStatus,
      switchStatus: tempQuery ? "" : prevParams.switchStatus,
      controlStatus: tempQuery ? "" : prevParams.controlStatus,
    }));
    if (tempQuery) {
      setConnectionStatus("");
      setSwitchStatus("");
      setControlStatus("");
    }
  };

  const {
    data: dsmMeters,
    isLoading: isDSMMetersLoading,
    isSuccess: isDSMMetersSuccess,
    isError: isDSMMetersError,
  } = useGetDSMMetersQuery(queryParams, {
    pollingInterval: 60000,
  });
  React.useEffect(() => {
    if (isDSMMetersSuccess) {
      setFilteredRows(dsmMeters.data);
    }
  }, [isDSMMetersSuccess]);
  React.useEffect(() => {
    if (isDSMMetersSuccess && dsmMeters) {
      const filtered = dsmMeters.data;

      setFilteredRows(filtered);
    }
  }, [isDSMMetersSuccess, dsmMeters]);

  if (isDSMMetersLoading) {
    return (
      <>
        <Grid
          style={{ marginLeft: "50px" }}
          item
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
        <Grid item alignItems="center" justifyContent="center"></Grid>
        <p>Loading Table</p>
      </>
    );
  } else if (isDSMMetersError) {
    return (
      <Grid
        style={{ marginLeft: "50px" }}
        item
        alignItems="center"
        justifyContent="center"
      >
        Error retrieving DSM Meter Table
      </Grid>
    );
  } else {
    return (
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ width: "98.99%", mb:5 }}
        >
          <Paper sx={{ borderRadius: 3, width: "100%" }}>
            <Grid container>
              <Grid item>
                <ToggleButtonGroup
                  sx={{ marginTop: 3, marginLeft: 3 }}
                  color="primary"
                  value={connectionStatus}
                  exclusive
                  onChange={handleConnectionFilterChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="Connected">Connected</ToggleButton>
                  <ToggleButton value="Disconnected">Disconnected</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  sx={{ marginTop: 3, marginLeft: 3 }}
                  color="primary"
                  value={switchStatus}
                  exclusive
                  onChange={handleSwitchStatusFilterChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="On">On</ToggleButton>
                  <ToggleButton value="Off">Off</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  sx={{ marginTop: 3, marginLeft: 3 }}
                  color="primary"
                  value={controlStatus}
                  exclusive
                  onChange={handleControlFilterChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="Nil">Nil</ToggleButton>
                  <ToggleButton value="PS">PS</ToggleButton>
                  <ToggleButton value="LS">LS</ToggleButton>
                  <ToggleButton value="Waiting">Waiting</ToggleButton>
                  <ToggleButton value="Recovering">Recovering</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  sx={{ marginTop: 3, marginLeft: 3 }}
                  color="primary"
                  value={isBaseline}
                  exclusive
                  onChange={handleisBaselineFilterChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="True">Baseline</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item sx={{ marginLeft: 3, marginRight: 3 }}>
                <TextField
                  sx={{ marginTop: 3 }}
                  name="search-bar"
                  size="small"
                  className="inputRounded"
                  id="search-bar"
                  variant="outlined"
                  value={tempQuery}
                  placeholder="Search Serial Number"
                  onChange={(e) => setTempQuery(e.target.value)}
                  onBlur={(e) => handleSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRoundedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <DataGrid
              sx={{ borderRadius: 3, marginTop: 3 }}
              rows={filteredRows}
              columns={columns}
              autoHeight
              // loading={isDSMMetersLoading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              autoWidth
              pageSizeOptions={[25]}
              disableSelectionOnClick
              disableRowSelectionOnClick={true}
              columnBuffer={3}
              paginationMode="server"
              rowCount={dsmMeters?.total_devices}
              onPaginationModelChange={(paginationModel) => {
                setParamsQuery((prevParams) => ({
                  ...prevParams,
                  page: paginationModel.page,
                  pageSize: paginationModel.pageSize,
                }));
              }}
            />
          </Paper>
        </Grid>
      </>
    );
  }
}
