import React from "react";
import { Box, Typography, Grid, Paper, Card, Button } from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MunicipalityTable from "../MunicipalityDasboard/MunicipalityTable";
import DateRangePickerMunicipality from "../MunicipalityDasboard/DateRangePickerMunicipality";
import {
  useGetMVTableDataQuery,
  useGetMuniReportViewQuery,
} from "../MunicipalityDasboard/municipalityDataAPISlice";
import DownloadIcon from "@mui/icons-material/Download";
import { useGetFieldAreaListQuery } from "../api/apiSlice";
const TechnicalReportPage = () => {
  const [countStockField, setCountStockField] = React.useState(0);
  const [fieldAreas, setFieldAreas] = React.useState(0);

  const { data: areas, isSuccess: isGetAreasSuccess } =
    useGetFieldAreaListQuery();

  React.useEffect(() => {
    if (isGetAreasSuccess && areas.field_areas.length > 0) {
      const totalStockCount = areas.field_areas.reduce(
        (acc, item) => acc + item.stock_item_count,
        0
      );
      setCountStockField(totalStockCount);
      setFieldAreas(areas.field_areas.length);
    }
  }, [isGetAreasSuccess, areas, countStockField]);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [startDate, setstartDate] = React.useState(dayjs().subtract(30, "day"));
  const [endDate, setendDate] = React.useState(dayjs());

  const handleStartDateChange = (newDate) => {
    setstartDate(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setendDate(newDate);
  };

  const request = {
    startDate: startDate.format("YYYY-MM-DD"),
    endDate: endDate.format("YYYY-MM-DD"),
    user_timezone: userTimezone,
  };

  const {
    data: municipalityTableData,
    isLoading: isLoadingMunicipalityTableData,
    isSuccess: isSuccessMunicipalityTableData,
    isError: isErrorMunicipalityTableData,
    error: errorMessageMunicipalityTableData,
  } = useGetMVTableDataQuery(request);

  const {
    data: muniReportData,
    isLoading: isLoadingmuniReportData,
    isSuccess: isSuccessmuniReportData,
    isError: isErrormuniReportData,
    error: errorMessagemuniReportData,
  } = useGetMuniReportViewQuery(request);

  const handleDownloadCSV = () => {
    let csvContent = "Category,Month,Value\n";

    const addKeyValuePairsToCSV = (category, dataObj) => {
      Object.entries(dataObj).forEach(([month, value]) => {
        csvContent += `${category},${month},${value}\n`;
      });
    };

    if (muniReportData) {
      if (muniReportData.demand_shaved) {
        addKeyValuePairsToCSV("Demand Shaved", muniReportData.demand_shaved);
      }

      if (muniReportData.target_max) {
        addKeyValuePairsToCSV("Target Maximum", muniReportData.target_max);
      }

      if (muniReportData.admd) {
        addKeyValuePairsToCSV("ADMD", muniReportData.admd);
      }

      if (
        muniReportData.energy_with_intervention &&
        muniReportData.energy_with_intervention.length > 0
      ) {
        const totalEnergyWithIntervention =
          muniReportData.energy_with_intervention[0];
        csvContent += `Energy With Intervention,,${totalEnergyWithIntervention}\n`;
      }

      if (
        muniReportData.energy_shifted &&
        muniReportData.energy_shifted.length > 0
      ) {
        const totalEnergyShifted = muniReportData.energy_shifted[0];
        csvContent += `Energy Shifted,,${totalEnergyShifted}\n`;
      }
      csvContent += `Count Stock Field,,${countStockField}\n`;
      csvContent += `Field Areas,,${fieldAreas}\n`;
    }

    if (csvContent !== "Category,Month,Value\n") {
      const anchorEle = document.createElement("a");
      anchorEle.href = `data:text/csv;charset=utf-8,${encodeURI(csvContent)}`;
      anchorEle.target = "_blank";
      anchorEle.download = `MuniReport_${
        new Date().toISOString().split("T")[0]
      }.csv`;
      anchorEle.click();
    }
  };

  if (isSuccessmuniReportData) {
    return (
      <>
        <DatePickerSection
          startDate={startDate}
          endDate={endDate}
          setStartDate={setstartDate}
          setEndDate={setendDate}
          handleDownloadCSV={handleDownloadCSV}
        />

        <Grid
          sx={{ mb: 2 }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={0.5} />
          <Grid item xs={11}>
            <Box
              sx={{
                padding: "16px",
                backgroundColor: "#f9f9f9",
                minHeight: "100vh",
                borderRadius: "20px",
              }}
            >
              <Grid item xs={12} sx={{ mb: 5, ml: 2, mr: 2 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginBottom: "16px" }}
                >
                  <Grid item xs={10}>
                    <Typography variant="h6">Technical Report</Typography>
                  </Grid>
                  <Button
                    variant="text"
                    sx={{ color: "#000" }}
                    onClick={handleDownloadCSV}
                    endIcon={<DownloadIcon />}
                  >
                    Download Report
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mb: 5, mt: 2, ml: 2, mr: 2 }}>
                <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
                  <Grid item>
                    <Paper
                      sx={{
                        padding: "8px 16px",
                        backgroundColor: "#e0e0e0",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      NMBM
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mb: 1, mt: 2, ml: 2, mr: 2 }}>
                <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        backgroundColor: "#ffe0b2",
                        borderRadius: "5px",
                        p: 2,
                      }}
                    >
                      <Typography variant="subtitle1">
                        Period Consumption Total
                      </Typography>
                      <Typography variant="h6">
                        {muniReportData?.energy_with_intervention}MWh
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        backgroundColor: "#ffe0b2",
                        borderRadius: "5px",
                        p: 2,
                      }}
                    >
                      <Typography variant="subtitle1">
                        With Intervention
                      </Typography>
                      <Typography variant="h6">
                        {muniReportData?.energy_with_intervention}MWh
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        backgroundColor: "#ffe0b2",
                        borderRadius: "5px",
                        p: 2,
                      }}
                    >
                      <Typography variant="subtitle1">
                        Total Load Shifted
                      </Typography>
                      <Typography variant="h6">
                        {muniReportData?.energy_shifted}MWh
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mb: 5, ml: 2, mr: 2 }}>
                <Grid
                  container
                  spacing={0.5}
                  direction="row"
                  alignItems="stretch"
                >
                  <Grid item xs={2}>
                    <Card
                      sx={{
                        padding: 3,
                        display: "flex",
                        flexDirection: "column",
                        height: "80%",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Peak Shaved{" "}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item xs={10}>
                    <Card
                      sx={{
                        padding: 3,
                        display: "flex",
                        flexDirection: "column",
                        height: "80%",
                      }}
                    >
                      {muniReportData?.demand_shaved &&
                        Object.entries(muniReportData.demand_shaved).map(
                          ([month, value]) => (
                            <Grid item xs={3} key={month}>
                              {month}: {value}MVA
                            </Grid>
                          )
                        )}
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ mb: 5, mt: 2, ml: 2, mr: 2 }}>
                <Grid
                  container
                  spacing={0.5}
                  direction="row"
                  alignItems="stretch"
                >
                  <Grid item xs={2}>
                    <Card
                      sx={{
                        padding: 3,
                        display: "flex",
                        flexDirection: "column",
                        height: "80%",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Target Maximum Demand
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item xs={10}>
                    <Card
                      sx={{
                        padding: 3,
                        display: "flex",
                        flexDirection: "column",
                        height: "80%",
                      }}
                    >
                      {muniReportData?.target_max &&
                        Object.entries(muniReportData.target_max).map(
                          ([month, value]) => (
                            <Grid item xs={3} key={month}>
                              {month}: {value}MVA
                            </Grid>
                          )
                        )}
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mb: 5, mt: 2, ml: 2, mr: 2 }}>
                <Grid
                  container
                  spacing={0.5}
                  direction="row"
                  alignItems="stretch"
                >
                  <Grid item xs={2}>
                    <Card
                      sx={{
                        padding: 3,
                        display: "flex",
                        flexDirection: "column",
                        height: "80%",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            ADMD
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item xs={10}>
                    <Card
                      sx={{
                        padding: 3,
                        display: "flex",
                        flexDirection: "column",
                        height: "80%",
                      }}
                    >
                      {Object.entries(muniReportData?.admd).map(
                        ([month, value]) => (
                          <Grid item xs={3} key={month}>
                            {month}: {value}
                          </Grid>
                        )
                      )}
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2, mt: 2, ml: 2, mr: 2 }}>
                <MunicipalityTable
                  municipalityTableData={municipalityTableData}
                  isLoadingMunicipalityTableData={
                    isLoadingMunicipalityTableData
                  }
                  isSuccessMunicipalityTableData={
                    isSuccessMunicipalityTableData
                  }
                  isErrorMunicipalityTableData={isErrorMunicipalityTableData}
                  errorMessageMunicipalityTableData={
                    errorMessageMunicipalityTableData
                  }
                  startDate={startDate}
                  endDate={endDate}
                />
              </Grid>

              <Grid item xs={12} sx={{ mb: 5, mt: 2, ml: 2, mr: 2 }}>
                <Grid
                  container
                  spacing={0.5}
                  direction="row"
                  alignItems="stretch"
                >
                  <Grid item xs={2}>
                    <Card
                      sx={{
                        padding: 3,
                        display: "flex",
                        flexDirection: "column",
                        height: "80%",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Installation Information
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item xs={10}>
                    <Card
                      sx={{
                        padding: 3,
                        display: "flex",
                        flexDirection: "column",
                        height: "80%",
                      }}
                    >
                      <Typography>
                        Installations: <strong>{countStockField}</strong>
                      </Typography>
                      <Typography>
                        Field Areas: <strong>{fieldAreas}</strong>
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={0.5} />
        </Grid>
      </>
    );
  } else return <></>;
};

const DatePickerSection = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => (
  <Grid container spacing={0} alignItems="center" sx={{ mt: 25 }}>
    <DateRangePickerMunicipality
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      startDate={startDate}
      endDate={endDate}
    />
  </Grid>
);

export default TechnicalReportPage;
