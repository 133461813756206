import { createTheme } from "@mui/material/styles";

const theme = createTheme({

  bottomNavStyle: {
    floatingActionStyle: {
      margin: "0px",
      top: "auto",
      right: "20px",
      bottom: "20px",
      left: "auto",
      position: "fixed",
    },
    toolTipStyle: {
      maxWidth: "none",
      whiteSpace: "nowrap",
    },
    toolTipStyle: {
      maxWidth: "620px",
    },
  },

  mainButton: {
    root: {
      fontFamily: ["helvetica", "serif"].join(","),
      border: 0,
      color: "#fff",
      background: '#FDB940',
      '&:hover': {
        color: '#fff',
        background: '#FDB940',
      },
    }
  },

  modalStyle: {
    root: {
      backdropFilter: 'blur(2px)',
    }
  },

  purpleButtonStyle: {
    root: {
      fontFamily: ["helvetica", "serif"].join(","),
      border: 0,
      borderRadius: 20,
      background: '#805088',
      color: "#fff",
      '&:hover': {
        color: '#fff',
        background: '#805088',
      },
    }
  },

  
  whiteButtonStyle: {
    root: {
          
      background: '#fff',
      color: "#000",
      minWidth: "200px",
      maxWidth: "200px"
     
    }
  },

  offwhiteButtonStyle: {
    root: {
          
      background: '#FFD891',
      color: "#000",
      minWidth: "200px",
      maxWidth: "200px"
     
    }
  },

  yellowButtonStyle: {
    root: {
          
      background: '#FDB940',
      color: "#000",
     
    }
  },
  blackButtonStyle: {
    root: {
          
      color: "#000",
     
    }
  },
  redButtonStyle: {
    root: {
      fontFamily: ["helvetica", "serif"].join(","),
      border: 0,
      borderRadius: 20,
      background: '#e34e24',
      color: "#fff",
      '&:hover': {
        color: '#fff',
        background: '#e34e24',
      },
    }
  },

});

export default theme;
