import React from "react";
import { Card, CardContent, Typography, Avatar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserName } from "../../Features/Authentication/authSlice";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useDispatch } from "react-redux";
import { logout } from "../../Features/Authentication/authSlice";

import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import {
  ListItemIcon,
} from "@mui/material";
 function UserCard() {
  const user_name = useSelector(getUserName);

  return (
    <Card>
      <CardContent style={{ display: "flex", alignItems: "center" }}>
        <Avatar sx={{mr:1}}>{user_name.charAt(0)}</Avatar>
        <div>
          <Typography >{user_name}</Typography>
        </div>
      </CardContent>
    </Card>
  );
}
export default function ProfileDropDown() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(logout());
    setAnchorEl(null);
  };
  return (
    <div>
      <AccountCircleIcon onClick={handleClick} />
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 2300 }}
      >
        <MenuItem>
          <UserCard />{" "}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
