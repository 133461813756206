import Grid from "@mui/material/Grid";
import { useState } from "react";
import React, { useEffect, useRef } from "react";
import dayjs from "dayjs";
import { LoadScript } from "@react-google-maps/api";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useGetMapDetailsQuery } from "../api/apiSlice";

const styles = {
  container: {
    margin: "0 auto",
    padding: "0 16px",
    maxWidth: "1350px",
    marginTop: 70,
  },
};

export default function OperatorMapOverview() {
  const [startDate] = useState(dayjs().subtract(1, "day"));
  const [endDate] = useState(dayjs());
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data: address_data, isSuccess: isGetAddressSuccess, isError: isGetAddressError } = useGetMapDetailsQuery();

  if (isGetAddressSuccess) {
    return (
      <>
        <Grid style={styles.container}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="left"
            sx={{ mt: 10 }}
          >
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            >
              <MapWithPins
                coordinates={address_data}
              />
            </LoadScript>

          </Grid>
        </Grid>
      </>
    );
  }
  else {
    return(<></>)
  }
}


function MapWithPins({ coordinates }) {
  const mapRef = useRef(null);
  console.log(coordinates)
  useEffect(() => {
    if (window.google) {
      // Initialize the map
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: -33.9610, lng: 25.6007 }, // Centered on Port Elizabeth
        zoom: 13,
      });

      const markers = coordinates.map((coord) => {
        const marker = new window.google.maps.Marker({
          position: { lat: coord.latitude, lng: coord.longitude },
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: coord.connected ? "green" : "red",
            fillOpacity: 1,
            scale: 8,
            strokeWeight: 1,
            strokeColor: "#000",
          },
        });

        // Create an InfoWindow for the marker
        const infoWindow = new window.google.maps.InfoWindow({
          content: `<div style="font-size: 14px; color: #333;">
                        <strong>${coord.address || "No description"}</strong>
                      </div>`,
        });

        // Add hover listener to display the InfoWindow
        marker.addListener("mouseover", () => infoWindow.open(map, marker));
        marker.addListener("mouseout", () => infoWindow.close());

        return marker;
      });

      const getClusterColor = (cluster) => {
        const markers = cluster.getMarkers();
        let connectedCount = 0;

        markers.forEach((marker) => {
          if (marker.getIcon().fillColor === "green") {
            connectedCount++;
          }
        });

        // Majority rule: Green if most markers are connected, otherwise Red
        return connectedCount >= markers.length / 2 ? "green" : "red";
      };

      // Custom Cluster Renderer
      const clusterRenderer = ({ count, position, markers, map }) => {
        const clusterColor = getClusterColor({ getMarkers: () => markers });

        return new window.google.maps.Marker({
          position,
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: clusterColor,
            fillOpacity: 0.8,
            scale: 15 + count / 2, // Adjust size based on count
            strokeWeight: 2,
            strokeColor: "#000",
          },
          label: {
            text: String(count), // Show number of markers in cluster
            color: "white",
            fontWeight: "bold",
            fontSize: "12px",
          },
          map,
        });
      };

      // Initialize Marker Clusterer with custom renderer
      new MarkerClusterer({
        map,
        markers,
        minClusterSize: 5,
        renderer: { render: clusterRenderer },
      });

    }
  }, [coordinates]);

  return (
    <div
      ref={mapRef}
      style={{
        height: "500px",
        width: "100%",
      }}
    />
  );
}