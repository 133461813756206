//React
import * as React from "react";
import { useState, useEffect } from "react";
//Material UI
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import AlertTitle from "@mui/material/AlertTitle";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Dayjs } from "dayjs";
// import dayjs from 'dayjs';
import "dayjs/locale/zh-cn";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
//Redux
import {
  useGetSchedulePSListQuery,
  useAddSchedulePSMutation,
  useUpdateSchedulePSMutation,
  useDeleteSchedulePSMutation,
  useUpdateBulkSchedulePSMutation,
} from "../UserSettings/scheduleApiSlice";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";

export default function SchedulePS() {
  const {
    data: schedule_data,
    isSuccess: isScheduleSuccess,
    refetch,
  } = useGetSchedulePSListQuery();
  const [
    addSchedulePS,
    { data: addData, isSuccess: isAddSuccess, isError: isAddError },
  ] = useAddSchedulePSMutation();

  const [open, setopen] = React.useState(false);
  const [bulkArray, setBulkArray] = useState([]);
  const [saved, setSaved] = React.useState("Saved!");
  const [openCloseDialog, setopenCloseDialog] = React.useState(false);

  const handleOpenCloseDialog = () => {
    setopenCloseDialog(true);
  };
  React.useEffect(() => {
    if (isScheduleSuccess) {
      setBulkArray(schedule_data);
    }
  }, [isScheduleSuccess, schedule_data]);
  const handleOpen = () => {
    setopen(true);
  };

  const handleClose = () => {
    setopen(false);
  };

  React.useEffect(() => {
    if (isAddSuccess) {
      refetch();
    }
  }, [refetch, isAddSuccess]);

  const addSchedule = (event) => {
    const body = {
      day: "Sunday",
      start_time: "00:00:00",
      end_time: "21:59:59",
      target: 0.0,
    };
    const request = {
      body: body,
    };

    addSchedulePS(request);
  };
  const [
    updateBulkSchedulePS,
    { isSuccess: isUpdateSuccess, isError: isUpdateError },
  ] = useUpdateBulkSchedulePSMutation();

  React.useEffect(() => {
    if (isUpdateSuccess) {
      setSaved("Saved!");
    } else if (isUpdateError) {
      setSaved("Save");
    } else {
      setSaved("Save");
    }
  }, [isUpdateSuccess]);
  const handleBulkUpdate = () => {
    const request = {
      body: bulkArray,
    };
    updateBulkSchedulePS(request);
  };
  if (isScheduleSuccess) {
    return (
      <>
        <ListItem sx={{ marginTop: 2 }} button onClick={handleOpen}>
          <ListItemIcon>
            <GpsFixedIcon />
          </ListItemIcon>
          <ListItemText primary="Peak Shaving Targets" />
        </ListItem>
        <ConfirmCloseDialog
          openCloseDialog={openCloseDialog}
          setopenCloseDialog={setopenCloseDialog}
          open={open}
          setopen={setopen}
        />
        <div>
          <Dialog
            BackdropProps={{ invisible: true }}
            fullWidth
            maxWidth="xl"
            open={open}
            onClose={() => setopen(false)}
            sx={{mt:5}}
          >
            <DialogTitle>Peak Shaving Schedule</DialogTitle>
            <DialogContent>
              <Grid container>
                {schedule_data?.map((schedule, index) => (
                  <Grid key={schedule.id} container>
                    <PeakShaveComponent
                      schedule={schedule}
                      index={index}
                      bulkArray={bulkArray}
                      setBulkArray={setBulkArray}
                      setSaved={setSaved}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid item align="left" xs={6}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={addSchedule}
                    sx={{ mr: 2 }}
                  >
                    Add new
                  </Button>
                </Grid>
                <Grid item align="right" xs={4} />
                <Grid item align="right" xs={1}>
                  <Button
                    variant="text"
                    onClick={
                      saved == "Save" ? handleOpenCloseDialog : handleClose
                    }
                    sx={{ mr: 2 }}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item align="right" xs={1}>
                  <Button
                    variant="outlined"
                    startIcon={<SaveIcon />}
                    disabled={saved == "Save" ? false : true}
                    onClick={handleBulkUpdate}
                    sx={{ mr: 2 }}
                  >
                    {saved}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

function PeakShaveComponent({
  schedule,
  index,
  bulkArray,
  setBulkArray,
  setSaved,
}) {
  const [deleteSchedulePS] = useDeleteSchedulePSMutation();
  const saTimeOffset = 2 * 60 * 60 * 1000;
  const [startTime, setstartTime] = React.useState(
    dayjs("2023-01-01T" + schedule.start_time)
  );
  const [endTime, setendTime] = React.useState(
    dayjs("2023-01-01T" + schedule.end_time)
  );

  const [day_type, setday_type] = useState(schedule.day);
  const [target_val, settarget] = useState(schedule.target);
  const [priorityVal, setpriorityVal] = useState(schedule.priority);
  const [startDate, setStartDate] = useState(dayjs(schedule.start_date));
  const [endDate, setEndDate] = useState(dayjs(schedule.end_date));
  const [currently_deleting_index, setCurrentlyDeletingIndex] = useState("");
  const day = ["Weekdays", "Saturday", "Sunday", "All"];
  const [dataArray, setDataArray] = useState({
    id: schedule.id,
    day: day_type,
    priority: priorityVal,
    target: target_val,
    start_time: startTime,
    end_time: endTime,
    start_date: startDate,
    end_date: endDate,
  });

  const setDay = (day) => {
    setday_type(day);
    setDataArray({ ...dataArray, day: day });
    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === schedule.id) {
          return {
            ...item,
            day: day,
          };
        } else {
          return item;
        }
      })
    );

    setSaved("Save");
  };
  const setTarget = (target_value) => {
    console.log(target_value, "target value");
    settarget(target_value);
    setDataArray({ ...dataArray, target: target_value });
    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === schedule.id) {
          console.log("a match");
          return {
            ...item,
            target: target_value,
          };
        } else {
          return item;
        }
      }),
      console.log(bulkArray)
    );

    setSaved("Save");
  };

  const setPriority = (priority) => {
    setpriorityVal(priority);
    setDataArray({ ...dataArray, priority: priority });
    console.log(priority, "priority");
    console.log(dataArray, "data arr");
    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === schedule.id) {
          console.log("a match");
          return {
            ...item,
            priority: priority,
          };
        } else {
          return item;
        }
      }),
      console.log(bulkArray, "bulk arr")
    );

    setSaved("Save");
  };

  const onChangeStart = (time: Dayjs) => {
    const saTimeOffset = 2 * 60 * 60 * 1000;
    const utcStartTime = time.subtract(
      saTimeOffset / (60 * 60 * 1000),
      "hours"
    );
    setstartTime(utcStartTime);
    setDataArray({
      ...dataArray,
      start_time:
        utcStartTime.$H + ":" + utcStartTime.$m + ":" + utcStartTime.$s,
    });
    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === schedule.id) {
          return {
            ...item,
            start_time:
              utcStartTime.$H + ":" + utcStartTime.$m + ":" + utcStartTime.$s,
          };
        } else {
          return item;
        }
      })
    );
    setSaved("Save");
  };
  const onChangeEnd = (time: Dayjs) => {
    const saTimeOffset = 2 * 60 * 60 * 1000;
    const utcEndTime = time.subtract(saTimeOffset / (60 * 60 * 1000), "hours");
    setendTime(utcEndTime);

    setDataArray({
      ...dataArray,
      end_time: utcEndTime.$H + ":" + utcEndTime.$m + ":" + utcEndTime.$s,
    });
    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === schedule.id) {
          return {
            ...item,
            end_time: utcEndTime.$H + ":" + utcEndTime.$m + ":" + utcEndTime.$s,
          };
        } else {
          return item;
        }
      })
    );
    setSaved("Save");
  };

  const onChangeStartDate = (date: Dayjs) => {
    const startOfDay = date.set("hour", 0).set("minute", 0).set("second", 0);

    setStartDate(startOfDay);

    setDataArray({
      ...dataArray,
      start_date: startOfDay.format("YYYY-MM-DD HH:mm:ss"),
    });

    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === schedule.id) {
          return {
            ...item,
            start_date: startOfDay.format("YYYY-MM-DD HH:mm:ss"),
          };
        } else {
          return item;
        }
      })
    );
    setSaved("Save");
  };

  const onChangeEndDate = (date: Dayjs) => {
    const endOfDay = date.set("hour", 21).set("minute", 59).set("second", 59);

    setEndDate(endOfDay);

    setDataArray({
      ...dataArray,
      end_date: endOfDay.format("YYYY-MM-DD HH:mm:ss"),
    });

    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === schedule.id) {
          return {
            ...item,
            end_date: endOfDay.format("YYYY-MM-DD HH:mm:ss"),
          };
        } else {
          return item;
        }
      })
    );
    setSaved("Save");
  };

  const handleDeleteButton = (index) => {
    setCurrentlyDeletingIndex(index);
  };

  const handleCancelDelete = () => {
    setCurrentlyDeletingIndex("");
  };

  const handleConfirmDelete = (schedule_id) => {
    deleteSchedulePS({ schedule_id });
    setCurrentlyDeletingIndex("");
  };

  return (
    <>
      <Grid xs={11}>
        <Grid container sx={{ p: 1 }} gap={1}>
          <Grid item xs={0.5} sx={{ mt: 1 }}>
            <Typography>PS {schedule.id}</Typography>
          </Grid>
          <Grid item xs={1} sx={{ mt: 1 }}>
            <TextField
              value={priorityVal}
              label="Priority"
              onChange={(e) => setPriority(e.target.value)}
            />
          </Grid>
          <Grid item xs={1.5}>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id="test-select-label">Day</InputLabel>
              <Select
                labelId="type-select-label"
                label="Day"
                id="day_type"
                name="day_type"
                value={day_type}
                required
                onChange={(e) => setDay(e.target.value)}
              >
                {day.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs} required>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  value={startTime.add(saTimeOffset, "ms")}
                  required
                  label="Start Time"
                  onChange={onChangeStart}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs} required>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  value={endTime.add(saTimeOffset, "ms")}
                  required
                  label="End Time"
                  onChange={onChangeEnd}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={1} sx={{ mt: 1 }}>
            <TextField
              value={target_val}
              required
              label="Target (VA)"
              onChange={(e) => setTarget(e.target.value)}
            />
          </Grid>
          <Grid item xs={1.5} sx={{ mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="zh-cn">
              <MobileDatePicker
                label="Start Date"
                format="YYYY/MM/DD"
                value={dayjs(startDate)}
                onChange={onChangeStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={1.5} sx={{ mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="zh-cn">
              <MobileDatePicker
                label="End Date"
                format="YYYY/MM/DD"
                value={dayjs(endDate)}
                onChange={onChangeEndDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={0.5}>
        <IconButton
          color="error"
          size="large"
          onClick={() => handleDeleteButton(index)}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>

      <Grid xs={2} />
      <Grid xs={8}>
        <Collapse in={index === currently_deleting_index}>
          <Alert severity="error">
            <AlertTitle>Caution!</AlertTitle>
            Are you sure you would like to <strong>DELETE</strong> this entry ?
            <strong>This action can not be undone</strong>
          </Alert>
          <Button
            color="error"
            size="small"
            variant="contained"
            onClick={handleCancelDelete}
          >
            No Cancel
          </Button>
          <Button
            // style={theme.updateButtonStyle.root}
            variant="contained"
            size="small"
            color="success"
            sx={{ ml: 1 }}
            onClick={() => handleConfirmDelete(schedule.id)}
          >
            Yes I am sure
          </Button>
        </Collapse>
      </Grid>
      <Grid xs={2} />
    </>
  );
}

function ConfirmCloseDialog({
  setopenCloseDialog,
  openCloseDialog,
  setopen,
  open,
}) {
  const handleCloseAll = () => {
    setopen(false);
    setopenCloseDialog(false);
  };
  return (
    <>
      <div>
        <Dialog
          BackdropProps={{ invisible: true }}
          fullWidth
          maxWidth="md"
          open={openCloseDialog}
          onClose={() => setopenCloseDialog(false)}
        >
          <DialogTitle>
            Are you sure you want to close without saving changes?
          </DialogTitle>
          <DialogContent>
            <Typography>Your changes will be lost</Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item align="right" xs={9} />
              <Grid item align="right" xs={1}>
                <Button
                  variant="text"
                  onClick={() => setopenCloseDialog(false)}
                  sx={{ mr: 2 }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item align="right" xs={2}>
                <Button
                  variant="contained"
                  onClick={handleCloseAll}
                  sx={{ mr: 2 }}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
