import * as React from "react";
import Box from "@mui/material/Box";
import { ReactComponent as SvgFile } from "./logo.svg";
import { useState } from "react";
import Stepper from "./Stepper";
import { Nav } from "./Elements";
import { Grid } from "@mui/material";
import { getsteps, getCurrentStep } from "./stepperSlice";
import { useSelector } from "react-redux";
import CancelJobButton from "../CancelJobButton";
import InstallationAssistance from "../InstallationAssistance";
import { useGetJobCardStateQuery } from "../../api/apiSlice";
import { useDispatch } from "react-redux";

import "@fontsource/roboto/400.css";

export default function NavbarStepper({ step }) {
  const stepsGlobal = useSelector(getCurrentStep);
  const [currentStep, setCurrentStep] = useState(step ? step : stepsGlobal);
  const steps = useSelector(getsteps);

  const { data: job_card_data, isSuccess: isGetJobCardStateSuccess } =
    useGetJobCardStateQuery();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (step) setCurrentStep(step);
  }, [stepsGlobal, step, currentStep]);

  const circleStyle = {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "#FDB940",
    color: "black",
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
  };
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  };

  const headingStyle = {
    color: "#FDB940",
    fontSize: "16px",
    fontFamily: ["Roboto"],
  };

  return (
    <Box sx={{ mb: 15, background: "#FDB940" }}>
      <Nav>
        <br />
        <Grid container alignItems="center" spacing={0.5} sx={{ mt: -2 }}>
          <Grid item xs={2} align="center">
            {job_card_data?.install_state == "NEW" ? (
              <CancelJobButton />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={8} align="center">
            <div style={containerStyle}>
              <div style={circleStyle}>{currentStep + 1}</div>
              <div style={headingStyle}>
                <StepperHeading step={currentStep} steps={steps} />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <InstallationAssistance />
          </Grid>
          <Grid item xs={12} align="center" sx={{ ml: 0.5, mt: -0.5 }}>
            <Stepper steps={steps} currentStep={currentStep} />
          </Grid>
         
        </Grid>
      </Nav>
    </Box>
  );
}

function StepperHeading({ step, steps }) {
  if (steps.length === 10) {
    switch (step) {
      case 0:
        return "Details";
      case 1:
        return "Scan QR";
      case 2:
        return "Work Area Picture";
      case 3:
        return "Take Pictures";
      case 4:
        return "Take Pictures";
      case 5:
        return "Install";
      case 6:
        return "Test Connection";
      case 7:
        return "Take Pictures";
      case 8:
        return "Take Pictures";
      case 9:
        return "Work Area Picture";
      default:
        return "Details";
    }
  } else if (steps.length <= 8) {
    switch (step) {
      case 0:
        return "Scan QR";

      case 4:
        return "Install";

      default:
        return "Take Pictures";
    }
  } else if (steps.length >= 11) {
    switch (step) {
      case 0:
        return "Scan QR";

      case 4:
        return "Install";
      case 5:
        return "Scan QR";
      case 6:
        return "Details";
      case 7:
        return "Install";
      case 8:
        return "Test Connection";

      default:
        return "Take Pictures";
    }
  }
}
