import ADMDGraph from "./ADMDGraph";
import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useGetADMDGraphDataQuery } from "../api/apiSlice";
import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import GDGraph from "./GeyserDemandPercentageGraph";

const styles = {
  container: {
    // margin: "0 auto",
    // padding: "0 16px",
    maxWidth: "1700px",
    marginTop: 55,
  },
};

export default function ADMDPage() {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selected_date, setSelectedDate] = React.useState(dayjs());

  const handleSelectedDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const request = {
    selected_date: selected_date.format("YYYY-MM-DD"),
    user_timezone: userTimezone,
  };

  const { data: admd_data, isSuccess: isGetADMDGraphDataSuccess } =
    useGetADMDGraphDataQuery(request);

  React.useEffect(() => {}, [selected_date]);

  if (isGetADMDGraphDataSuccess) {
    return (
      <>
        <Grid style={styles.container}>
          <Card sx={{ borderRadius: "12px", backgroundColor: "#fff", mb: 4 }}>
            <Grid
              sx={{ mb: 2, mt: 2 }}
              container
              alignItems="center"
              justifyContent="center"
            >
              <DatePickerSection
                selected_date={selected_date}
                handleSelectedDateChange={handleSelectedDateChange}
              />
            </Grid>
          </Card>
          <Grid container alignItems="left">
            <Grid item xs={0.5} />
            <Grid item xs={11}>
              <ADMDGraph graph_data={admd_data} />
            </Grid>
            <Grid item xs={0.5} />
          </Grid>

          <Grid container alignItems="left" sx={{mt:-5}}>
            <Grid item xs={0.5} />
            <Grid item xs={11}>
              <GDGraph graph_data={admd_data} />
            </Grid>
            <Grid item xs={0.5} />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
}

function DatePickerSection({ selected_date, handleSelectedDateChange }) {
  return (
    <Grid
      container
      alignItems="left"
      style={{
        position: "fixed",
        top: "64px",
        backgroundColor: "white",
        zIndex: 1000,
        maxWidth: "99%",
      }}
    >
      <Grid item xs={0.5} />
      <Grid item xs={11}>
        <Grid
          container
          style={{
            backgroundColor: "white",
            zIndex: 1000,
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",

            margin: "0 auto",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["MobileDateTimePicker"]}>
              <Grid container alignItems="center">
                <Grid item xs={0.5} />
                <Grid item xs={11} sx={{ ml: 2, mt: 2, mb: 2 }}>
                  {/* <Typography variant = "h6">Date:</Typography> */}
                  <DemoItem label="Date">
                    <DatePicker
                      value={selected_date}
                      onChange={handleSelectedDateChange}
                    />
                  </DemoItem>
                </Grid>
                <Grid item xs={0.5} />
              </Grid>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid item xs={0.5} />
    </Grid>
  );
}
