//React
import * as React from "react";
import { useState, useEffect } from "react";
//Material UI
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import AlertTitle from "@mui/material/AlertTitle";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Dayjs } from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
//Redux
import {
  useGetPublicHolidayListQuery,
  useAddPublicHolidayMutation,
  useUpdateBulkPublicHolidayMutation,
  useDeletePublicHolidayMutation,
} from "../api/financialApiSlice";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import EventIcon from "@mui/icons-material/Event";

export default function PublicHoliday() {
  const {
    data: holiday_data,
    isSuccess: isHolidaySuccess,
    refetch,
  } = useGetPublicHolidayListQuery();
  const [
    addHoliday,
    { data: addData, isSuccess: isAddSuccess, isError: isAddError },
  ] = useAddPublicHolidayMutation();

  const [open, setopen] = React.useState(false);
  const [saved, setSaved] = React.useState("Saved!");
  const [bulkArray, setBulkArray] = useState([]);
  const [openCloseDialog, setopenCloseDialog] = React.useState(false);

  const handleOpenCloseDialog = () => {
    setopenCloseDialog(true);
  };
  React.useEffect(() => {
    if (isHolidaySuccess) {
      setBulkArray(holiday_data);
    }
  }, [isHolidaySuccess, holiday_data]);

  React.useEffect(() => {
    if (isAddSuccess) {
      refetch();
    }
  }, [refetch, isAddSuccess]);

  const handleOpen = () => {
    setopen(true);
  };

  const handleClose = () => {
    setopen(false);
  };

  const addPublicHoliday = (event) => {
    const body = {
      holiday_date: "",
      day_of_week: "Saturday",
    };
    const request = {
      body: body,
    };

    addHoliday(request);
  };

  const [
    updateBulkPublicHoliday,
    { isSuccess: isUpdateSuccess, isError: isUpdateError },
  ] = useUpdateBulkPublicHolidayMutation();

  const handleBulkUpdate = () => {
    const request = {
      body: bulkArray,
    };
    updateBulkPublicHoliday(request);
  };
  React.useEffect(() => {
    if (isUpdateSuccess) {
      setSaved("Saved!");
    } else if (isUpdateError) {
      setSaved("Save");
    } else {
      setSaved("Save");
    }
  }, [isUpdateSuccess]);

  if (isHolidaySuccess) {
    return (
      <>
        <ListItem sx={{ marginTop: 2 }} button onClick={handleOpen}>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary="Public Holiday" />
        </ListItem>
        <ConfirmCloseDialog
          openCloseDialog={openCloseDialog}
          setopenCloseDialog={setopenCloseDialog}
          open={open}
          setopen={setopen}
        />
        <div>
          <Dialog
            BackdropProps={{ invisible: true }}
            fullWidth
            maxWidth="md"
            open={open}
            onClose={() => setopen(false)}
            sx={{mt:5}}
          >
            <DialogTitle>Public Holiday</DialogTitle>
            <DialogContent>
              <Grid container>
                {holiday_data?.map((holiday, index) => (
                  <Grid key={holiday.id} container>
                    <HolidayComponent
                      holiday={holiday}
                      index={index}
                      setSaved={setSaved}
                      setBulkArray={setBulkArray}
                      bulkArray={bulkArray}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid item align="left" xs={6}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={addPublicHoliday}
                    sx={{ mr: 2 }}
                  >
                    Add new
                  </Button>
                </Grid>

                <Grid item align="right" xs={3} />
                <Grid item align="right" xs={1}>
                  <Button
                    variant="text"
                    onClick={
                      saved == "Save" ? handleOpenCloseDialog : handleClose
                    }
                    sx={{ mr: 2 }}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item align="right" xs={1}>
                  <Button
                    variant="outlined"
                    startIcon={<SaveIcon />}
                    disabled={saved == "Save" ? false : true}
                    onClick={handleBulkUpdate}
                    sx={{ mr: 2 }}
                  >
                    {saved}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

function HolidayComponent({
  holiday,
  index,
  setSaved,
  setBulkArray,
  bulkArray,
}) {
  const [deletePublicHoliday] = useDeletePublicHolidayMutation();

  const [holidayDate, setholiday_date] = useState(dayjs(holiday.holiday_date));
  const [dayOfWeek, setday_of_week] = useState(holiday.day_of_week);

  const [currently_deleting_index, setCurrentlyDeletingIndex] = useState("");
  const day_of_week = ["Saturday", "Sunday"];
  const [dataArray, setDataArray] = useState({
    id: holiday.id,
    day_of_week: dayOfWeek,
    holiday_date: holidayDate,
  });

  const handleDeleteButton = (index) => {
    setCurrentlyDeletingIndex(index);
  };

  const handleCancelDelete = () => {
    setCurrentlyDeletingIndex("");
  };

  const handleConfirmDelete = (holiday_id) => {
    deletePublicHoliday({ holiday_id });
    setCurrentlyDeletingIndex("");
  };

  const onChangePublicHoliday = (date: Dayjs) => {
    const startOfDay = date.set("hour", 0).set("minute", 0).set("second", 0);

    setholiday_date(startOfDay);

    setDataArray({
      ...dataArray,
      holiday_date: startOfDay.format("YYYY-MM-DD"),
    });

    setBulkArray(
      bulkArray.map((item) => {
        if (item.id === holiday.id) {
          return {
            ...item,
            holiday_date: startOfDay.format("YYYY-MM-DD"),
          };
        } else {
          return item;
        }
      })
    );
    setSaved("Save");
  };

  return (
    <>
      <Grid xs={11}>
        <Grid container sx={{ p: 1 }} gap={2}>
          <Grid item xs={4} sx={{ mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="zh-cn">
              <MobileDatePicker
                label="Holiday Date"
                format="YYYY/MM/DD"
                value={holidayDate}
                onChange={onChangePublicHoliday}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id="test-select-label">Day</InputLabel>
              <Select
                labelId="type-select-label"
                label="Day"
                id="day_of_week"
                name="day_of_week"
                value={dayOfWeek}
                required
                onChange={(e) => setday_of_week(e.target.value)}
              >
                {day_of_week.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={1}>
        <IconButton
          color="error"
          size="large"
          onClick={() => handleDeleteButton(index)}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
        </Grid>
      </Grid>

      

      <Grid xs={2} />
      <Grid xs={8}>
        <Collapse in={index === currently_deleting_index}>
          <Alert severity="error">
            <AlertTitle>Caution!</AlertTitle>
            Are you sure you would like to <strong>DELETE</strong> this entry ?
            <strong>This action can not be undone</strong>
          </Alert>
          <Button
            color="error"
            size="small"
            variant="contained"
            onClick={handleCancelDelete}
          >
            No Cancel
          </Button>
          <Button
            // style={theme.updateButtonStyle.root}
            variant="contained"
            size="small"
            color="success"
            sx={{ ml: 1 }}
            onClick={() => handleConfirmDelete(holiday.id)}
          >
            Yes I am sure
          </Button>
        </Collapse>
      </Grid>
      <Grid xs={2} />
    </>
  );
}

function ConfirmCloseDialog({
  setopenCloseDialog,
  openCloseDialog,
  setopen,
  open,
}) {
  const handleCloseAll = () => {
    setopen(false);
    setopenCloseDialog(false);
  };
  return (
    <>
      <div>
        <Dialog
          BackdropProps={{ invisible: true }}
          fullWidth
          maxWidth="md"
          open={openCloseDialog}
          onClose={() => setopenCloseDialog(false)}
        >
          <DialogTitle>
            Are you sure you want to close without saving changes?
          </DialogTitle>
          <DialogContent>
            <Typography>Your changes will be lost</Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item align="right" xs={9} />
              <Grid item align="right" xs={1}>
                <Button
                  variant="text"
                  onClick={() => setopenCloseDialog(false)}
                  sx={{ mr: 2 }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item align="right" xs={2}>
                <Button
                  variant="contained"
                  onClick={handleCloseAll}
                  sx={{ mr: 2 }}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
