import { useState } from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import {
    useGetMeterRawDataQuery,
    useGetMeterInfoQuery,
} from "./rawDataAPISlice";
import dayjs from "dayjs";
import RawDataRegisterToggle from "./RawDataRegisterToggle";
import HeadingRawData from "./HeadingRawData";
import DateRangePickerRawData from "./DateRangePickerRawData";
import RawDataGraph from "./RawDataGraph";
export default function LandingPageRawData({}) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { meter_id } = useParams();

    const [startDate, setStartDate] = useState(dayjs().subtract(1, "day"));
    const [endDate, setEndDate] = useState(dayjs());
    const [register, setRegister] = useState("ActivePower");

    const request = {
        queryParams: {
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            userTimezone: userTimezone,
            register: register,
        },
        meterID: meter_id,
    };

    const { data: meterInfo } = useGetMeterInfoQuery(meter_id);
    const {
        data: meterRawData,
        isLoading: isLoadingMeterRawData,
        isSuccess: isSuccessMeterRawData,
        isError: isErrorMeterRawData,
        error: errorMessageMeterRawData,
    } = useGetMeterRawDataQuery(request);

    return (
        <>
        <DateRangePickerRawData
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        meterInfo={meterInfo}
    />
        <Grid
            container
            style={{
                marginTop: 180,
                padding: "2em",
                maxWidth: "1700px",
                marginLeft: "auto",
                marginRight: "auto",
            }}
        >
         
            <Grid item xs={12}>
      
            </Grid>
            <Grid item xs={12}>
                <RawDataRegisterToggle
                    register={register}
                    setRegister={setRegister}
                />
            </Grid>
            <Grid item xs={12}>
                <RawDataGraph
                    meterInfo={meterInfo}
                    meterRawData={meterRawData}
                    isSuccessMeterRawData={isSuccessMeterRawData}
                    isErrorMeterRawData={isErrorMeterRawData}
                    errorMessageMeterRawData={errorMessageMeterRawData}
                    isLoadingMeterRawData={isLoadingMeterRawData}
                    startDate={startDate}
                    endDate={endDate}
                />
            </Grid>
        </Grid>
        </>
    );
}
