import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
} from "@mui/material";
import { Menu as MenuIcon, Refresh as RefreshIcon } from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import tjommiePic from "../images/tjommie.png";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SvgFile } from "../../Global/images/tjommieNav.svg";
import ProfileDropDown from "./ProfileDropDown";

const styles = {
  appBar: {
    color: "#333333",
    backgroundColor: "#FDB940",
    width: "100%",
    zIndex: 1400,
  },
  menuButton: {
    marginRight: 25,
  },
  refreshButton: {
    marginLeft: "auto",
  },
  tjommiePic: {
    height: 62,
    marginLeft: "auto",
  },
  municipalityText: {
    marginRight: "auto",
    marginLeft: 4,
  },
};

export default function UtilityNavbar() {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleHome = () => {
    navigate("/utility/");
    setIsDrawerOpen(false);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleRefresh = () => {
    console.log("Refreshing...");
  };

  const handleADMD = () => {
    navigate("/municipality/admd/");
    setIsDrawerOpen(false);
  };

  const handleInstalls = () => {
    navigate("/municipality/installs/");
    setIsDrawerOpen(false);
  };

  const handleMV = () => {
    navigate("/utility/mv/");
    setIsDrawerOpen(false);
  };
  return (
    <>
      <AppBar position="absolute" style={styles.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            style={styles.menuButton}
            onClick={isDrawerOpen ? handleDrawerClose : handleDrawerOpen}
          >
            {isDrawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>

          <Typography variant="h6" noWrap style={styles.municipalityText}>
            DSM Utility Dashboard
          </Typography>

          <IconButton
            color="inherit"
            aria-label="refresh"
            style={styles.refreshButton}
            onClick={handleRefresh}
          >
            <img src={tjommiePic} alt="Tjommie" style={styles.tjommiePic} />
            <RefreshIcon />
          </IconButton>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="search"
            sx={{ color: "#fff", mt: 1 }}
          >
            <ProfileDropDown />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      >
        <Grid container alignItems="center" sx={{ mt: 10, p: 3 }}>
          <SvgFile />
        </Grid>
        <List sx={{ width: 260 }}>
          <ListItem
            button
            onClick={handleHome}
            sx={{
              "&:hover": {
                backgroundColor: "rgba(253, 185, 64, 0.5)",
              },
            }}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          {/* <ListItem
            button
            sx={{
              "&:hover": {
                backgroundColor: "rgba(253, 185, 64, 0.5)",
              },
            }}
            onClick={handleADMD}
          >
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <ListItemText primary="ADMD" />
          </ListItem>

          <ListItem
            button
            sx={{
              "&:hover": {
                backgroundColor: "rgba(253, 185, 64, 0.5)",
              },
            }}
            onClick={handleInstalls}
          >
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <ListItemText primary="Installs" />
          </ListItem> */}{" "}
          <ListItem
            button
            sx={{
              "&:hover": {
                backgroundColor: "rgba(253, 185, 64, 0.5)",
              },
            }}
            onClick={handleMV}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="M&V" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
