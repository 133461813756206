//React
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { LoadScript } from "@react-google-maps/api";
//Material UI
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import theme from "../../Global/theme";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
//Redux
import {
  useAddjobCardMutation,
  useGetJobCardStateQuery,
  useEditJobCardMutation,
} from "../api/apiSlice";
import { useDispatch } from "react-redux";
//Routing
import { useNavigate, useLocation } from "react-router-dom";
//Components
import NavbarStepper from "./Stepper/NavBarStepper";

export default function ClientDetailsPage() {
  const dispatch = useDispatch();

  const [address, setAddress] = React.useState("");
  const [unit_number, setUnitNumber] = React.useState("");
  const [latitude, setLatitude] = React.useState("");
  const [longitude, setLongitude] = React.useState("");
  const [geyser_name, setGeyserName] = React.useState("");
  const [customer_consent, setCustomerConsent] = React.useState("Unwilling");
  const [disabled_button, setDisabled] = React.useState(true);

  const [error_alert, setErrorAlert] = React.useState(false);
  const [error_message, setErrorMessage] = React.useState(false);
  const [abort_dialog_open, setAbortDialogOpen] = React.useState(false);
  const [breaker_control, setbreaker_control] = React.useState(false);
  const [solar_geyser, setsolar_geyser] = React.useState(false);
  const [solar_pv, setsolar_pv] = React.useState(false);
  const [ripple_replacement, setripple_replacement] = React.useState(false);
  const [timer_installed, settimer_installed] = React.useState(false);
  const [heat_pump, setheatpump] = React.useState(false);
  const [ripple_bypass, setripplebypass] = React.useState(false);
  const { data: job_card_state_data, isSuccess: isGetJobCardStateSuccess } =
    useGetJobCardStateQuery();
  const [
    editJobCard,
    {
      data: job_card,
      isSuccess: isEditJobCardSuccess,
      isError: isEditJobCardError,
    },
  ] = useEditJobCardMutation();

  const setCoordinates = ({ latitude, longitude }) => {
    setLatitude(latitude);
    setLongitude(longitude);
  };

  useEffect(() => {
    if (isGetJobCardStateSuccess) {
      if (job_card_state_data.install_state === "RE") {
        setAddress(job_card_state_data.address);
        setUnitNumber(job_card_state_data.unit_number);
        setGeyserName(job_card_state_data.geyser_name);
        setCustomerConsent(job_card_state_data.customer_consent);
        setbreaker_control(job_card_state_data.breaker_control);
        setsolar_geyser(job_card_state_data.solar_geyser);
        setsolar_pv(job_card_state_data.solar_pv);
        setripple_replacement(job_card_state_data.ripple_replacement);
        settimer_installed(job_card_state_data.timer_installed);
        setheatpump(job_card_state_data.heat_pump);
        setripplebypass(job_card_state_data.ripple_bypass);
      }
    }
  }, [isGetJobCardStateSuccess, job_card_state_data]);
  const [
    addJobCard,
    {
      data: job_card_data,
      isSuccess: isAddJobCardSuccess,
      isError: isAddJobCardError,
      error: addJobCardError,
    },
  ] = useAddjobCardMutation();

  const handleChange = (event) => {
    const { name, checked } = event.target;
    switch (name) {
      case "breaker_control":
        setbreaker_control(checked);
        break;
      case "solar_geyser":
        setsolar_geyser(checked);
        break;
      case "solar_pv":
        setsolar_pv(checked);
        break;
      case "timer_installed":
        settimer_installed(checked);
        break;
      case "ripple_replacement":
        setripple_replacement(checked);
        break;
      case "heat_pump":
        setheatpump(checked);
        break;
      case "ripple_bypass":
        setripplebypass(checked);
        break;
      default:
        break;
    }
  };
  const handleOpenAbortDialog = () => {
    if (address == "") {
      setErrorMessage("Address is missing!");
      setErrorAlert(true);
    } else {
      setAbortDialogOpen(true);
    }
  };

  const handleCloseAbortDialog = () => {
    setAbortDialogOpen(false);
  };

  const handleChangeCustomerConsent = (event) => {
    setCustomerConsent(event.target.value);
  };

  let navigate = useNavigate();


  const handleAddJobCard = () => {
    if (address == "") {
      setErrorMessage("Address is missing!");
      setErrorAlert(true);
    } else if (geyser_name == "") {
      setErrorMessage("Geyser Name/Description is missing!");
      setErrorAlert(true);
    } else {
      if (job_card_state_data.install_state === "RE") {
        const body = {
          address: address,
          unit_number: unit_number,
          latitude: latitude,
          longitude: longitude,
          geyser_name: geyser_name,
          customer_consent: customer_consent,
          breaker_control: breaker_control,
          solar_geyser: solar_geyser,
          solar_pv: solar_pv,
          ripple_replacement: ripple_replacement,
          timer_installed: timer_installed,
          aborted: false,
          heat_pump: heat_pump,
          ripple_bypass: ripple_bypass,
          state: "AMP",
          reference_id: job_card_state_data.reference_id,
        };
        editJobCard({ body: body });
      } else {
        const body = {
          address: address,
          unit_number: unit_number,
          latitude: latitude,
          longitude: longitude,
          geyser_name: geyser_name,
          customer_consent: customer_consent,
          breaker_control: breaker_control,
          solar_geyser: solar_geyser,
          solar_pv: solar_pv,
          ripple_replacement: ripple_replacement,
          timer_installed: timer_installed,
          aborted: false,
          heat_pump: heat_pump,
          ripple_bypass: ripple_bypass,
        };

        addJobCard({ body: body });
      }
    }
  };

  const handleAbortJob = () => {
    localStorage.setItem("clientDetails", false);
    const body = {
      address: address,
      customer_consent: customer_consent,
      aborted: true,
    };

    addJobCard({ body: body });
  };

  useEffect(() => {
    if (isAddJobCardSuccess) {
      localStorage.setItem("clientDetails", false);
      navigate(`/installer/${job_card_data.job_card_reference_id}/qrcode/`);
    }
  }, [isAddJobCardSuccess]);

  useEffect(() => {
    if (isEditJobCardSuccess) {
      localStorage.setItem("clientDetails", false);
      navigate(`/installer/`);
    }
  }, [isEditJobCardSuccess]);

  useEffect(() => {
    if (isAddJobCardError) {
      setErrorAlert(true);
      setErrorMessage(addJobCardError.data.error_message);
    }
  }, [isAddJobCardError]);

  useEffect(() => {
    if (customer_consent === "Willing") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [customer_consent]);



  const libraries = ["places"];
  return (
    <>
      <NavbarStepper />
      <DialogAbort
        open={abort_dialog_open}
        handleCloseDialog={handleCloseAbortDialog}
        handleAbortJob={handleAbortJob}
      />
      <Typography align="center" sx={{ fontSize: 16, color: "grey", mt: -2 }}>
        Installation Details
      </Typography>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ mt: -2 }}
      >
        <Grid item xs={12} align="center">
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            libraries={libraries}
          >
            <AddressTextField
              setterVariable={address}
              setterFunction={setAddress}
              setCoordinates={setCoordinates}
              label="Address"
            />
          </LoadScript>
        </Grid>

        <Grid item xs={12} align="center">
          <GenericTextField
            setterVariable={unit_number}
            setterFunction={setUnitNumber}
            label="Unit Number"
          />
        </Grid>

        <Grid item xs={12} align="center">
          <GenericTextField
            setterVariable={geyser_name}
            setterFunction={setGeyserName}
            label="Geyser Name / Description"
          />
        </Grid>

        <Grid item xs={12} align="center">
          <RadioButtons
            handleChange={handleChangeCustomerConsent}
            value={customer_consent}
          />
        </Grid>

        <Grid item xs={12} align="center">
          <MyCheckboxes
            breaker_control={breaker_control}
            solar_geyser={solar_geyser}
            solar_pv={solar_pv}
            ripple_replacement={ripple_replacement}
            timer_installed={timer_installed}
            heat_pump={heat_pump}
            ripple_bypass={ripple_bypass}
            handleChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} align="center">
          <ErrorAlert
            error_alert={error_alert}
            setErrorAlert={setErrorAlert}
            error_message={error_message}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" spacing={0.5}>
        <Grid
          item
          xs={11}
          align="right"
          style={{ marginBottom: 20, marginTop: 40 }}
          sx={{ mr: 1 }}
        >
          <Button
            sx={theme.blackButtonStyle.root}
            variant="outlined"
            onClick={handleOpenAbortDialog}
            disabled={!disabled_button}
            style={{ marginRight: 5 }}
          >
            Abort
          </Button>
          <Button
            sx={{...theme.yellowButtonStyle.root, border:"none"}}
            variant="outlined"
            onClick={() => handleAddJobCard()}
            disabled={disabled_button}
            style={{ marginLeft: 5 }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

function AddressTextField({ setterVariable, setterFunction, label, setCoordinates }) {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(""); // Local state for the input value

  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ["geocode"],
        componentRestrictions: { country: "ZA" },
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const formattedAddress = place.formatted_address || inputValue;

        const coordinates = place.geometry?.location
          ? {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          }
          : null;

        setInputValue(formattedAddress);
        setterFunction(formattedAddress);
        if (coordinates) {
          setCoordinates(coordinates);
        }

        console.log(formattedAddress);
        console.log(coordinates);
      });
    }
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setterFunction(e.target.value);
  };

  return (
    <Grid item xs={10} align="center" style={{ marginBottom: 10 }}>
      <TextField
        inputRef={inputRef}
        margin="normal"
        label={label}
        value={inputValue} // Controlled input
        onChange={handleInputChange} // Handle user input
        variant="standard"
        fullWidth
      />
    </Grid>
  );
}


function GenericTextField({ setterVariable, setterFunction, label }) {
  return (
    <>
      <Grid item xs={1} />
      <Grid item xs={10} align="center" style={{ marginBottom: 10 }}>
        <TextField
          onChange={(e) => setterFunction(e.target.value)}
          margin="normal"
          label={label}
          value={setterVariable}
          variant="standard"
          fullWidth
        />
      </Grid>
      <Grid item xs={1} />
    </>
  );
}

function RadioButtons({
  handleChange,
  value,
}) {
  return (
    <>
      <Grid item xs={1} />
      <Grid
        item
        xs={10}
        align="left"
        style={{ marginBottom: 10 }}
        sx={{ mt: 1 }}
      >
        <Typography align="left" sx={{ fontSize: 12, color: "grey" }}>
          Customer Detail - select one
        </Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value={"Willing"}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#FDB940",
                    },
                  }}
                />
              }
              label="Customer Willing"
            />

            <FormControlLabel
              value={"Unwilling"}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#FDB940",
                    },
                  }}
                />
              }
              label="Customer Unwilling"
            />

            <FormControlLabel
              value={"Absent"}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#FDB940",
                    },
                  }}
                />
              }
              label="Customer Absent"
            />
          </RadioGroup>
        </FormControl>
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={1} />
    </>
  );
}

function MyCheckboxes({
  breaker_control,
  solar_geyser,
  solar_pv,
  ripple_replacement,
  timer_installed,
  heat_pump,
  ripple_bypass,
  handleChange,
}) {
  return (
    <>
      <Grid item xs={1} />
      <Grid item xs={10} align="left" style={{ marginBottom: 10 }}>
        <FormGroup>
          <GenericCheckbox
            description="Breaker Control"
            checked={breaker_control}
            handleChange={handleChange}
            name="breaker_control"
            label="User switches breaker"
          />

          <GenericCheckbox
            description="Geyser type"
            checked={solar_geyser}
            handleChange={handleChange}
            name="solar_geyser"
            label="Solar Geyser"
          />

          <GenericCheckbox
            description="Solar PV Detail"
            checked={solar_pv}
            handleChange={handleChange}
            name="solar_pv"
            label="Solar PV on roof"
          />

          <GenericCheckbox
            description="Timer or Device"
            checked={timer_installed}
            handleChange={handleChange}
            name="timer_installed"
            label="Timer or device installed"
          />

          <GenericCheckbox
            description="Ripple Replacement"
            checked={ripple_replacement}
            handleChange={handleChange}
            name="ripple_replacement"
            label="Ripple Replacement"
          />

          <GenericCheckbox
            description="Heat Pump"
            checked={heat_pump}
            handleChange={handleChange}
            name="heat_pump"
            label="Heat Pump"
          />

          <GenericCheckbox
            description="Ripple Bypass"
            checked={ripple_bypass}
            handleChange={handleChange}
            name="ripple_bypass"
            label="Ripple Bypass"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={1} />
    </>
  );
}

function GenericCheckbox({ description, checked, handleChange, name, label }) {
  return (
    <>
      {/* <Typography align="left" sx={{ fontSize: 12, color: "grey", mt: 1, mb: 1 }}>{description}</Typography> */}
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            name={name}
            sx={{
              "&.Mui-checked": {
                color: "#FDB940",
              },
            }}
          />
        }
        label={label}
      />
      <Divider variant="middle" />
    </>
  );
}

function ErrorAlert({ error_alert, setErrorAlert, error_message }) {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorAlert(false);
  };

  return (
    <div>
      <Snackbar
        open={error_alert}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {error_message}
        </Alert>
      </Snackbar>
    </div>
  );
}

function DialogAbort({ open, handleCloseDialog, handleAbortJob }) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        Are you sure you want to abort the current installation?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You will need to start a new install.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} variant="text">
          Back
        </Button>
        <Button onClick={() => handleAbortJob()} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
