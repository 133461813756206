import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export default function DateRangePickerRawData({
  setEndDate,
  setStartDate,
  meterInfo,
}) {
  const [open, setOpen] = useState(false);

  const { serial_number } = useParams();
  const navigate = useNavigate();
  const [startDatePlaceHolder, setStartDatePlaceHolder] = useState(
    dayjs().subtract(1, "day")
  );
  const [endDatePlaceHolder, setEndDatePlaceHolder] = useState(dayjs());
  const handlePrevPage = () => {
    navigate(-1);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleStartDateChange = (newDate) => {
    setStartDatePlaceHolder(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setEndDatePlaceHolder(newDate);
  };

  const handleDateChangeConfirm = () => {
    setStartDate(startDatePlaceHolder);
    setEndDate(endDatePlaceHolder);
  };

  return (
    <Grid
      container
      style={{
        position: "fixed",
        top: "64px",
        backgroundColor: "white",
        zIndex: 1000,
        maxWidth: "99%",
      }}
    >
      <Grid item xs={0.5}/>
      <Grid item xs={0.5}>
        <ArrowBackIcon
          size="large"
          sx={{ color: "#000", mb: 2, mt: 4, cursor: "pointer" }}
          onClick={handlePrevPage}
        />
      </Grid>
      <Grid item xs={10}>
        <Grid
          container
          style={{
            backgroundColor: "white",
            zIndex: 1000,
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",

            margin: "0 auto",
          }}
        >
          <Grid item xs={1} />
          <Grid item xs={10} sx={{ ml: -10 }}>
            <Grid
              container
              style={{
                marginTop: "2em",
                marginBottom: "1em",
              }}
            >
              <Grid
                container
                style={{
                  paddingLeft: "2em",
                }}
                sx={{ ml: -5 }}
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="bold" noWrap>
                    {startDatePlaceHolder.format("DD/MM/YYYY")} -{" "}
                    {endDatePlaceHolder.format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<CalendarTodayIcon />}
                    sx={{
                      borderColor: "#FDB940",
                      color: "black",
                      borderRadius: "8px",
                      ml: "16px",
                      padding: "8px 16px",
                      "&:hover": {
                        borderColor: "#FDB940",
                      },
                    }}
                    onClick={handleOpen}
                  >
                    DATE RANGE
                  </Button>

                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Select Date Range</DialogTitle>
                    <DialogContent>
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        style={{ marginTop: "1px" }}
                      >
                        <Grid item>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format="DD/MM/YYYY"
                              label="Start Date"
                              value={startDatePlaceHolder}
                              onChange={handleStartDateChange}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format="DD/MM/YYYY"
                              label="End Date"
                              value={endDatePlaceHolder}
                              onChange={handleEndDateChange}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} sx={{ color: "black" }}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleDateChangeConfirm}
                        sx={{ backgroundColor: "#FDB940", color: "black" }}
                      >
                        Apply
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>

                <Grid item sx={{ ml: 2 }}>
                  <Grid container >
                    <Grid item>
                      <Typography variant="h6">
                        Serial Number - {serial_number}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">
                        UUID - {meterInfo?.uuid}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}
